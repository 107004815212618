import Home from '@/views/Home.vue';
import BusinessClubs from '@/views/BusinessClubs.vue';
import Associations from '@/views/Associations.vue';
import SportClubs from '@/views/SportClubs.vue';
import App from '@/views/App.vue';
import Website from '@/views/Website.vue';
import Contact from '@/views/Contact.vue';
import RequestDemo from '@/views/RequestDemo.vue';

export default [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/businessclubs',
    name: 'businessclubs',
    component: BusinessClubs,
  },
  {
    path: '/verenigingen',
    name: 'verenigingen',
    component: Associations,
  },
  {
    path: '/sportclubs',
    name: 'sportclubs',
    component: SportClubs,
  },
  {
    path: '/app',
    name: 'app',
    component: App,
  },
  {
    path: '/website',
    name: 'website',
    component: Website,
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
  },
  {
    path: '/demo-aanvragen',
    name: 'demo-aanvragen',
    component: RequestDemo,
  },
];
