<template>
  <footer class="container">
    <div class="py-50 lg:py-100 w-full xl:w-4/5 mx-auto grid md:grid-cols-4 gap-20 md:gap-50 border-t border-t-gray-200">
      <div class="col-span-1">
        <h6 class="text-lg xl:text-xl font-semibold mb-10">
          Clubee
        </h6>
        <p class="text-gray-500">
          Dé software voor jouw businessclub, netwerkvereniging of sportclub.
          <br><br>
          <a
            href="/assets/200909-Privacy-cookieverklaring-Website-Clubee.pdf"
            target="_blank"
            rel="noopener noreferrer"
            class="text-black underline hover:no-underline"
          >
            Privacy &amp; cookieverklaring
          </a>
        </p>
      </div>
      <div class="col-span-1">
        <h6 class="text-lg xl:text-xl font-semibold mb-10">
          Snel naar
        </h6>
        <ul class="leading-loose">
          <li
            v-for="link in fastLinks"
            :key="`fast-link-${link.hash}`"
          >
            <router-link
              :to="{
                path: '/',
                hash: link.hash,
              }"
              class="underline hover:no-underline"
            >
              {{ link.title }}
            </router-link>
          </li>
        </ul>
      </div>
      <div class="col-span-1">
        <h6 class="text-lg xl:text-xl font-semibold mb-10">
          Voor jouw club
        </h6>
        <ul class="leading-loose">
          <li
            v-for="link in clubLinks"
            :key="`club-link-${link.href}`"
          >
            <router-link
              :to="link.href"
              class="underline hover:no-underline"
            >
              {{ link.title }}
            </router-link>
          </li>
        </ul>
      </div>
      <div class="col-span-1">
        <h6 class="text-lg xl:text-xl font-semibold mb-10">
          Contact
        </h6>
        <ul class="leading-loose">
          <li>
            <a
              href="mailto:info@clubee.nl"
              class="underline hover:no-underline"
            >
              info@clubee.nl
            </a>
          </li>
          <li>
            <a
              href="tel:+851304667"
              class="underline hover:no-underline"
            >
              085 - 130 4667
            </a>
          </li>
        </ul>

        <div class="flex items-center gap-x-8 mt-25">
          <span>Volg ons op</span>
          <a
            href="https://www.linkedin.com/company/clubee-nl/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="LinkedIn"
            class="text-black underline hover:no-underline"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 25 25"
              xmlns="http://www.w3.org/2000/svg"
            ><path
              d="M23.62 0C24.382 0 25 .618 25 1.38v22.24A1.38 1.38 0 0 1 23.62 25H1.38A1.38 1.38 0 0 1 0 23.62V1.38C0 .618.618 0 1.38 0h22.24zm-6.517 9.596c-1.92 0-3.122 1.04-3.344 1.768V9.698H9.996c.012.226.018 1.026.02 2.093v.51l.001.268v1.424c-.004 2.46-.016 5.209-.02 6.056l-.001.166v.098h3.763v-6.06c.002-.206.017-.4.082-.552.258-.638.814-1.3 1.814-1.3 1.306 0 1.9.98 1.9 2.419v5.492h3.8V14.41c0-3.287-1.871-4.814-4.252-4.814zm-9.153.102H4.6v10.614h3.35V9.698zm-1.737-5.01c-1.237 0-2.046.794-2.046 1.842 0 1.029.786 1.84 1.999 1.84h.024c1.259 0 2.043-.814 2.043-1.843-.024-1.047-.784-1.84-2.02-1.84z"
              fill="#007EBB"
            /></svg>
          </a>
          <a
            href="https://www.instagram.com/clubee_nl/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagramm"
            class="text-black underline hover:no-underline"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 132 132"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <defs>
                <linearGradient id="b">
                  <stop
                    offset="0"
                    stop-color="#3771c8"
                  />
                  <stop
                    stop-color="#3771c8"
                    offset=".128"
                  />
                  <stop
                    offset="1"
                    stop-color="#60f"
                    stop-opacity="0"
                  />
                </linearGradient>
                <linearGradient id="a">
                  <stop
                    offset="0"
                    stop-color="#fd5"
                  />
                  <stop
                    offset=".1"
                    stop-color="#fd5"
                  />
                  <stop
                    offset=".5"
                    stop-color="#ff543e"
                  />
                  <stop
                    offset="1"
                    stop-color="#c837ab"
                  />
                </linearGradient>
                <radialGradient
                  id="c"
                  cx="158.429"
                  cy="578.088"
                  r="65"
                  xlink:href="#a"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="matrix(0 -1.98198 1.8439 0 -1031.402 454.004)"
                  fx="158.429"
                  fy="578.088"
                />
                <radialGradient
                  id="d"
                  cx="147.694"
                  cy="473.455"
                  r="65"
                  xlink:href="#b"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="matrix(.17394 .86872 -3.5818 .71718 1648.348 -458.493)"
                  fx="147.694"
                  fy="473.455"
                />
              </defs>
              <path
                fill="url(#c)"
                d="M65.03 0C37.888 0 29.95.028 28.407.156c-5.57.463-9.036 1.34-12.812 3.22-2.91 1.445-5.205 3.12-7.47 5.468C4 13.126 1.5 18.394.595 24.656c-.44 3.04-.568 3.66-.594 19.188-.01 5.176 0 11.988 0 21.125 0 27.12.03 35.05.16 36.59.45 5.42 1.3 8.83 3.1 12.56 3.44 7.14 10.01 12.5 17.75 14.5 2.68.69 5.64 1.07 9.44 1.25 1.61.07 18.02.12 34.44.12 16.42 0 32.84-.02 34.41-.1 4.4-.207 6.955-.55 9.78-1.28 7.79-2.01 14.24-7.29 17.75-14.53 1.765-3.64 2.66-7.18 3.065-12.317.088-1.12.125-18.977.125-36.81 0-17.836-.04-35.66-.128-36.78-.41-5.22-1.305-8.73-3.127-12.44-1.495-3.037-3.155-5.305-5.565-7.624C116.9 4 111.64 1.5 105.372.596 102.335.157 101.73.027 86.19 0H65.03z"
                transform="translate(1.004 1)"
              />
              <path
                fill="url(#d)"
                d="M65.03 0C37.888 0 29.95.028 28.407.156c-5.57.463-9.036 1.34-12.812 3.22-2.91 1.445-5.205 3.12-7.47 5.468C4 13.126 1.5 18.394.595 24.656c-.44 3.04-.568 3.66-.594 19.188-.01 5.176 0 11.988 0 21.125 0 27.12.03 35.05.16 36.59.45 5.42 1.3 8.83 3.1 12.56 3.44 7.14 10.01 12.5 17.75 14.5 2.68.69 5.64 1.07 9.44 1.25 1.61.07 18.02.12 34.44.12 16.42 0 32.84-.02 34.41-.1 4.4-.207 6.955-.55 9.78-1.28 7.79-2.01 14.24-7.29 17.75-14.53 1.765-3.64 2.66-7.18 3.065-12.317.088-1.12.125-18.977.125-36.81 0-17.836-.04-35.66-.128-36.78-.41-5.22-1.305-8.73-3.127-12.44-1.495-3.037-3.155-5.305-5.565-7.624C116.9 4 111.64 1.5 105.372.596 102.335.157 101.73.027 86.19 0H65.03z"
                transform="translate(1.004 1)"
              />
              <path
                fill="#fff"
                d="M66.004 18c-13.036 0-14.672.057-19.792.29-5.11.234-8.598 1.043-11.65 2.23-3.157 1.226-5.835 2.866-8.503 5.535-2.67 2.668-4.31 5.346-5.54 8.502-1.19 3.053-2 6.542-2.23 11.65C18.06 51.327 18 52.964 18 66s.058 14.667.29 19.787c.235 5.11 1.044 8.598 2.23 11.65 1.227 3.157 2.867 5.835 5.536 8.503 2.667 2.67 5.345 4.314 8.5 5.54 3.054 1.187 6.543 1.996 11.652 2.23 5.12.233 6.755.29 19.79.29 13.037 0 14.668-.057 19.788-.29 5.11-.234 8.602-1.043 11.656-2.23 3.156-1.226 5.83-2.87 8.497-5.54 2.67-2.668 4.31-5.346 5.54-8.502 1.18-3.053 1.99-6.542 2.23-11.65.23-5.12.29-6.752.29-19.788 0-13.036-.06-14.672-.29-19.792-.24-5.11-1.05-8.598-2.23-11.65-1.23-3.157-2.87-5.835-5.54-8.503-2.67-2.67-5.34-4.31-8.5-5.535-3.06-1.187-6.55-1.996-11.66-2.23-5.12-.233-6.75-.29-19.79-.29zm-4.306 8.65c1.278-.002 2.704 0 4.306 0 12.816 0 14.335.046 19.396.276 4.68.214 7.22.996 8.912 1.653 2.24.87 3.837 1.91 5.516 3.59 1.68 1.68 2.72 3.28 3.592 5.52.657 1.69 1.44 4.23 1.653 8.91.23 5.06.28 6.58.28 19.39s-.05 14.33-.28 19.39c-.214 4.68-.996 7.22-1.653 8.91-.87 2.24-1.912 3.835-3.592 5.514-1.68 1.68-3.275 2.72-5.516 3.59-1.69.66-4.232 1.44-8.912 1.654-5.06.23-6.58.28-19.396.28-12.817 0-14.336-.05-19.396-.28-4.68-.216-7.22-.998-8.913-1.655-2.24-.87-3.84-1.91-5.52-3.59-1.68-1.68-2.72-3.276-3.592-5.517-.657-1.69-1.44-4.23-1.653-8.91-.23-5.06-.276-6.58-.276-19.398s.046-14.33.276-19.39c.214-4.68.996-7.22 1.653-8.912.87-2.24 1.912-3.84 3.592-5.52 1.68-1.68 3.28-2.72 5.52-3.592 1.692-.66 4.233-1.44 8.913-1.655 4.428-.2 6.144-.26 15.09-.27zm29.928 7.97c-3.18 0-5.76 2.577-5.76 5.758 0 3.18 2.58 5.76 5.76 5.76 3.18 0 5.76-2.58 5.76-5.76 0-3.18-2.58-5.76-5.76-5.76zm-25.622 6.73c-13.613 0-24.65 11.037-24.65 24.65 0 13.613 11.037 24.645 24.65 24.645C79.617 90.645 90.65 79.613 90.65 66S79.616 41.35 66.003 41.35zm0 8.65c8.836 0 16 7.163 16 16 0 8.836-7.164 16-16 16-8.837 0-16-7.164-16-16 0-8.837 7.163-16 16-16z"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      fastLinks: [
        {
          title: 'Wat is Clubee?',
          hash: '#club',
        },
        {
          title: 'Voordelen voor leden',
          hash: '#leden',
        },
        {
          title: 'Tarieven',
          hash: '#tarieven',
        },
        {
          title: 'Contact',
          hash: '/contact',
        },
        {
          title: 'Demo aanvragen',
          href: '/demo-aanvragen',
        },
      ],
      clubLinks: [
        {
          title: 'Businessclubs',
          href: '/businessclubs',
        },
        {
          title: 'Verenigingen',
          href: '/verenigingen',
        },
        {
          title: 'Sportclubs',
          href: '/sportclubs',
        },
      ],
    };
  },
});
</script>
