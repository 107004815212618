<!-- eslint-disable max-len, vue/max-len -->
<template>
  <section
    id="home"
    class="pt-100 md:pt-150"
  >
    <div class="absolute top-0 right-0 mt-100 lg:mt-150 w-full md:w-1/2 max-w-4xl">
      <div class="w-full pt-[100%] bg-gradient-to-r from-primary-400 to-primary-500 rounded-full ml-[20%]" />
      <img
        src="/img/hero-sportclubs.webp"
        alt="demo website Clubee"
        title="demo website Clubee"
        class="absolute top-0 right-0 mt-50 pl-25 md:-ml-50 md:pl-0"
      >
    </div>
    <div class="container relative mt-[110%] md:mt-0 xl:mt-50 2xl:mt-100">
      <div class="w-full md:w-1/2 max-w-4xl">
        <h1 class="text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold mb-25">
          Vraag een <span class="marker marker-lg">demo-website</span> aan
        </h1>
        <p class="text-gray-500 text-lg lg:text-xl mt-25 sm:max-w-xs lg:max-w-xl">
          Benieuwd naar de mogelijkheden van een clubee website voor jou vereniging of businessclub? Wij leggen je precies uit wat een demo inhoudt. Ontdek Clubee op een laagdrempelige manier en ervaar wat het platform voor jou kan betekenen
        </p>

        <div class="flex flex-col items-start">
          <router-link
            :to="{
              path: '/demo-aanvragen',
              hash: '#contact',
            }"
            class="inline-flex items-center gap-x-8 mt-40 font-semibold px-20 lg:px-25 py-10 lg:py-15 rounded-full bg-gradient-to-r from-primary-400 via-primary-500 to-primary-400 bg-size-200 bg-pos-0 hover:bg-pos-100 hover:no-underline transition-all"
          >
            <span class="inline-flex items-center justify-center bg-white/80 rounded-full size-30">
              <svg
                width="14"
                height="16"
                viewBox="0 0 14 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.7071 8.2929C14.0976 8.6834 14.0976 9.3166 13.7071 9.7071L7.7071 15.7071C7.3166 16.0976 6.68342 16.0976 6.29289 15.7071L0.29289 9.7071C-0.09763 9.3166 -0.09763 8.6834 0.29289 8.2929C0.68342 7.90237 1.31658 7.90237 1.70711 8.2929L6 12.5858V1C6 0.44772 6.44772 0 7 0C7.5523 0 8 0.44772 8 1V12.5858L12.2929 8.2929C12.6834 7.90237 13.3166 7.90237 13.7071 8.2929Z"
                  fill="#04283D"
                />
              </svg>

            </span>
            Demo aanvragen
          </router-link>
        </div>
      </div>
    </div>
  </section>

  <section class="pt-100 lg:pt-150 mt-100 lg:mt-150">
    <div class="container">
      <div class="rounded-3xl bg-gray-100 p-25 grid md:grid-cols-12 gap-20 md:gap-50 md:p-50 xl:p-100">
        <div class="col-span-5">
          <h2 class="text-4xl lg:text-5xl font-bold">
            Waarom een <br><span class="marker marker-lg">demo</span> aanvragen?
          </h2>
          <p class="text-gray-500 text-lg mt-15">
            Een demo is de perfecte manier om kennis te maken met alle functionaliteiten van Clubee. Of je nu een eenvoudige website in je eigen huisstijl wilt laten maken of een efficient systeem voor evenementenbeheer nodig heeft, tijdens de demo krijg je duidelijk beeld van Clubee.
          </p>
          <div class="mt-40 flex flex-col items-start lg:flex-row gap-8">
            <router-link
              :to="{
                path: '/demo-aanvragen',
                hash: '#contact',
              }"
              class="inline-flex font-semibold px-20 lg:px-25 py-10 lg:py-15 rounded-full bg-gradient-to-r from-primary-400 via-primary-500 to-primary-400 bg-size-200 bg-pos-0 hover:bg-pos-100 hover:no-underline transition-all"
            >
              Gratis demo aanvragen
            </router-link>
            <!-- <router-link
              :to="{
                path: '/demo-aanvragen',
                hash: '#contact',
              }"
              class="inline-flex font-semibold px-20 lg:px-25 py-10 lg:py-15 rounded-full text-white bg-gradient-to-r from-gray-600 via-gray-900 to-gray-600 bg-size-200 bg-pos-0 hover:bg-pos-100 hover:no-underline transition-all"
            >
              Demo bekijken
            </router-link> -->
          </div>
        </div>
        <div class="col-span-6 lg:col-span-5 lg:col-start-8">
          <figure class="aspect-square overflow-hidden rounded-2xl lg:aspect-[5/4]">
            <img
              src="/img/bgt.jpg"
              alt="Clubee"
              class="size-full object-cover"
            >
          </figure>
        </div>
      </div>
    </div>
  </section>

  <section>
    <div class="container">
      <div class="p-25 grid lg:grid-cols-12 gap-20 md:gap-50 md:p-50 md:pr-0 xl:p-100 xl:pr-0">
        <div class="col-span-5">
          <h2 class="text-4xl lg:text-5xl font-bold">
            Hoe werkt een<br> demo <span class="marker marker-lg">aanvraag?</span>
          </h2>
          <p class="text-gray-500 text-lg mt-15">
            Wanneer je een demo aanvraagt gaan wij gelijk aan de slag voor je. We plannen in overleg een moment in om de demo te geven, het liefst via een videocall. Hierin laten we alle functionaliteiten van Clubee zien en heb je direct de kans om specifieke vragen over ons platform te stellen. Ook bespreken we wensien die je hebt die voor jou vereniging of club van toepassing zijn
          </p>
          <div class="mt-40 flex flex-col items-start lg:flex-row gap-8">
            <router-link
              :to="{
                path: '/demo-aanvragen',
                hash: '#contact',
              }"
              class="inline-flex font-semibold px-20 lg:px-25 py-10 lg:py-15 rounded-full bg-gradient-to-r from-primary-400 via-primary-500 to-primary-400 bg-size-200 bg-pos-0 hover:bg-pos-100 hover:no-underline transition-all"
            >
              Gratis demo aanvragen
            </router-link>
            <!-- <router-link
              :to="{
                path: '/demo-aanvragen',
                hash: '#contact',
              }"
              class="inline-flex font-semibold px-20 lg:px-25 py-10 lg:py-15 rounded-full text-white bg-gradient-to-r from-gray-600 via-gray-900 to-gray-600 bg-size-200 bg-pos-0 hover:bg-pos-100 hover:no-underline transition-all"
            >
              Demo bekijken
            </router-link> -->
          </div>
        </div>
        <div class="col-span-6 lg:col-start-7 space-y-20">
          <div class="w-3/5 ml-[40%] bg-gray-100 rounded-xl p-20 flex items-center gap-x-40 text-lg font-semibold md:p-30">
            <span class="inline-flex shrink-0 items-center justify-center rounded-full size-40 text-lg bg-gradient-to-r from-primary-400 via-primary-500 to-primary-400">1</span>
            Aanvraag indienen
          </div>
          <div class="w-3/5 ml-[20%] bg-gray-100 rounded-xl p-20 flex items-center gap-x-40 text-lg font-semibold md:p-30">
            <span class="inline-flex shrink-0 items-center justify-center rounded-full size-40 text-lg bg-gradient-to-r from-primary-400 via-primary-500 to-primary-400">2</span>
            Afspraak plannen
          </div>
          <div class="w-3/5 ml-[30%] bg-gray-100 rounded-xl p-20 flex items-center gap-x-40 text-lg font-semibold md:p-30">
            <span class="inline-flex shrink-0 items-center justify-center rounded-full size-40 text-lg bg-gradient-to-r from-primary-400 via-primary-500 to-primary-400">3</span>
            Videocall met demonstratie
          </div>
          <div class="w-3/5 ml-[10%] bg-gray-100 rounded-xl p-20 flex items-center gap-x-40 text-lg font-semibold md:p-30">
            <span class="inline-flex shrink-0 items-center justify-center rounded-full size-40 text-lg bg-gradient-to-r from-primary-400 via-primary-500 to-primary-400">4</span>
            Vrijblijvende keuze
          </div>
        </div>
      </div>
    </div>
  </section>

  <contact-form-section
    id="contact"
    name="Demo aanvragen"
    class="mb-100"
    text="Met de gratis demo ervaar je zelf hoe ledenadministratie, een webste en app voor je businessclub werkt. Ervaar het gemak van Clubee en vraag direct een gratis demo aan."
  >
    <template #title>
      <h2 class="text-3xl xl:text-4xl font-bold mb-10">
        Vraag nu een <span class="marker marker-white">gratis demo</span> aan
      </h2>
    </template>
  </contact-form-section>
</template>

<script>
import { defineComponent } from 'vue';
import ContactFormSection from '@/components/ContactFormSection.vue';

export default defineComponent({
  components: {
    ContactFormSection,
  },
});
</script>
