<!-- eslint-disable max-len, vue/max-len -->
<template>
  <section
    id="home"
    class="pt-100 md:pt-150"
  >
    <div class="absolute top-0 right-0 mt-100 lg:mt-150 w-full md:w-1/2 max-w-4xl">
      <div class="w-full pt-[100%] bg-gradient-to-r from-primary-400 to-primary-500 rounded-full ml-[20%]" />
      <img
        src="/img/hero-sportclubs.webp"
        alt="sportclubs software Clubee"
        title="sportclubs software Clubee"
        class="absolute top-0 right-0 mt-50 pl-25 md:-ml-50 md:pl-0"
      >
    </div>
    <div class="container relative mt-[110%] md:mt-0 xl:mt-50 2xl:mt-100">
      <div class="w-full md:w-1/2 max-w-4xl">
        <h1 class="text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold mb-25">
          De software voor <span class="marker marker-lg">jouw sportclub</span>
        </h1>
        <h2 class="text-xl md:text-2xl lg:text-3xl xl:text-4xl font-semibold">
          Website, app en leden-administratie in één pakket
        </h2>
        <p class="text-gray-500 text-lg lg:text-xl mt-25 sm:max-w-xs lg:max-w-xl">
          Clubee is de alles-in-één software voor je sportclub. Binnen een dag beschik je al over een je eigen sportclub website en app, geheel in eigen huisstijl. En met de geïntegreerde ledenadministratie wordt beheer makkelijker dan ooit. Probeer Clubee  en vraag direct een gratis demo aan.
        </p>

        <div class="flex flex-col items-start">
          <router-link
            to="/demo-aanvragen"
            class="inline-flex mt-40 font-semibold px-20 lg:px-25 py-10 lg:py-15 rounded-full bg-gradient-to-r from-primary-400 via-primary-500 to-primary-400 bg-size-200 bg-pos-0 hover:bg-pos-100 hover:no-underline transition-all"
          >
            Demo aanvragen
          </router-link>

          <router-link
            :to="{
              path: '/',
              hash: '#club',
            }"
            class="inline-flex items-center mt-25 font-semibold group"
          >
            <span class="h-50 w-50 mr-10 bg-black bg-opacity-5 group-hover:bg-opacity-10 transition rounded-full flex items-center justify-center">
              <svg
                width="14"
                height="16"
                viewBox="0 0 14 16"
                xmlns="http://www.w3.org/2000/svg"
              ><path
                d="M13.707 8.293a1 1 0 0 1 0 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 1 1 1.414-1.414L6 12.586V1a1 1 0 0 1 2 0v11.586l4.293-4.293a1 1 0 0 1 1.414 0z"
                fill="#000"
                fill-rule="evenodd"
              /></svg>
            </span>
            Ontdek Clubee
          </router-link>
        </div>
      </div>
    </div>
  </section>

  <section class="pt-100 lg:pt-150 md:mt-100 2xl:mt-150">
    <div class="container">
      <div class="rounded-3xl bg-gray-100 p-25 md:p-50 xl:p-100">
        <div class="flex flex-wrap justify-between">
          <div class="w-full lg:w-4/5">
            <h2 class="text-3xl md:text-4xl xl:text-5xl font-bold">
              <span class="marker">Alles in één</span> voor jouw sportclub
            </h2>
            <p class="text-gray-500 mt-15">
              Clubee is de ideale oplossing voor sportclubs. Wil je snel een app en website voor je sportclub, in je eigen huisstijl? Dit is in mum van tijd geregeld. Daarnaast bespaart de geïntegreerde ledenadministratie je sportclub veel tijd en deel je gemakkelijk de agenda, evenementen, nieuws en foto's. Ontdek alle features van Clubee.
            </p>
            <router-link
              to="/demo-aanvragen"
              class="inline-flex mt-40 font-semibold px-20 lg:px-25 py-10 lg:py-15 rounded-full bg-gradient-to-r from-primary-400 via-primary-500 to-primary-400 bg-size-200 bg-pos-0 hover:bg-pos-100 hover:no-underline transition-all"
            >
              Demo aanvragen
            </router-link>
          </div>

          <div class="grid md:grid-cols-2 flex-wrap items-start gap-25 mt-50">
            <div class="col-span-1 h-full bg-white text-gray-500 text-sm rounded-2xl p-40">
              <h4 class="text-lg font-medium mb-5 text-black">
                Website in eigen huisstijl
              </h4>
              <p>
                Een website voor je sportclub helemaal in eigen huisstijl? Voeg je eigen kleuren, logo's en lettertypen toe en laat je club spreken.
              </p>
            </div>
            <div class="col-span-1 h-full bg-white text-gray-500 text-sm rounded-2xl p-40">
              <h4 class="text-lg font-medium mb-5 text-black">
                App in eigen huisstijl
              </h4>
              <p>
                Met de app voor je sportclub  app hebben je leden altijd toegang tot hun omgeving. Geef leden altijd en overal toegang tot de agenda, evenementen, nieuws en foto's.
              </p>
            </div>
          </div>
          <div class="grid md:grid-cols-3 flex-wrap items-start gap-25 mt-25">
            <div class="col-span-1 h-full bg-white text-gray-500 text-sm rounded-2xl p-40">
              <h4 class="text-lg font-medium mb-5 text-black">
                Ledenadministratie
              </h4>
              <p>
                Doe je je administratie nog in excel? Dan gaat de geïn tegreerde ledenadministratie voor sportclubs je veel tijd besparen. Maak direct een uitdraai van al jouw leden.
              </p>
            </div>
            <div class="col-span-1 h-full bg-white text-gray-500 text-sm rounded-2xl p-40">
              <h4 class="text-lg font-medium mb-5 text-black">
                Agenda en evenementen
              </h4>
              <p>
                Plan evenementen en bekijk de agenda van jouw sportclub. Leden geven zich makkelijk op voor events. Zo weet je direct wie er bij de volgende training of wedstrijd is.
              </p>
            </div>
            <div class="col-span-1 h-full bg-white text-gray-500 text-sm rounded-2xl p-40">
              <h4 class="text-lg font-medium mb-5 text-black">
                Nieuws en foto's delen
              </h4>
              <p>
                Deel nieuws op de website of app of stuur een pushbericht naar al je leden. Foto's deel je openbaar óf alleen met leden in een afgeschermde omgeving.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="pt-100 lg:pt-150">
    <div class="container">
      <div class="grid md:grid-cols-12 gap-50">
        <div class="col-span-full md:col-span-8">
          <blockquote class="relative pl-40 before:absolute before:inset-y-0 before:left-0 before:bg-gradient-to-b before:from-primary-400 before:to-primary-500 before:w-5">
            <span class="block text-2xl lg:text-3xl 2xl:text-4xl/tight font-bold italic">
              “Met Clubee zijn we in staat om snel en eenvoudig met onze leden te communiceren.”
            </span>
            <cite class="block mt-25 not-italic text-gray-500 text-lg xl:text-xl 2xl:text-2xl">-Steven Bodde, RTC Twente</cite>
          </blockquote>
        </div>
        <figure class="col-span-full md:col-span-4 md:col-start-9">
          <img
            src="/img/steven.webp"
            alt="Steven Bodde, RTC Twente"
            title="Steven Bodde, RTC Twente"
            class="rounded-3xl"
          >
        </figure>
      </div>
    </div>
  </section>

  <section class="pt-100 lg:pt-150">
    <div class="container">
      <div class="rounded-3xl bg-gray-100 p-25 grid md:grid-cols-12 gap-20 md:gap-50 md:p-50 xl:p-100">
        <div class="col-span-5">
          <h2 class="text-4xl lg:text-5xl font-bold">
            <span class="marker marker-lg">Voordelen</span> voor jouw sportclub leden
          </h2>
          <p class="text-gray-500 text-lg mt-15">
            Met Clubee maak je alles een stuk makkelijker voor je leden. Als lid vind je al het nieuws en alle evenementen op één plek op de website of app, je geeft je makklijk op voor events en ziet direct wie zich nog meer heeft opgegeven.
            <br><br>
            Ook vind je er alle verslagen en foto's van evenementen die hebben plaatsgevonden, in een al dan niet afgeschermde omgeving.
          </p>
          <router-link
            to="/demo-aanvragen"
            class="inline-flex mt-40 font-semibold px-20 lg:px-25 py-10 lg:py-15 rounded-full bg-gradient-to-r from-primary-400 via-primary-500 to-primary-400 bg-size-200 bg-pos-0 hover:bg-pos-100 hover:no-underline transition-all"
          >
            Demo aanvragen
          </router-link>
        </div>
        <div class="col-span-7">
          <div class="bg-white text-gray-500 text-sm rounded-2xl p-25 mt-50 ml-25 lg:ml-50">
            <h4 class="text-lg font-medium mb-5 text-black">
              Makkelijk aanmelden voor evenementen
            </h4>
            <p>
              Meld je met een druk op de knop aan of af voor evenementen, en zie wie er nog meer komt.
            </p>
          </div>

          <div class="bg-white text-gray-500 text-sm rounded-2xl p-25 mt-40 mr-25 lg:mr-50">
            <h4 class="text-lg font-medium mb-5 text-black">
              Altijd up to date van belangrijk nieuws
            </h4>
            <p>
              Met de website of app ben je altijd op de hoogte van het laatste nieuws. Een belangrijke melding? Dan krijg je direct een mail of push-notificatie.
            </p>
          </div>

          <div class="bg-white text-gray-500 text-sm rounded-2xl p-25 mt-40 ml-25 lg:ml-40 lg:mr-10">
            <h4 class="text-lg font-medium mb-5 text-black">
              Foto's van evenementen bekijken
            </h4>
            <p>
              Bekijk foto's en video's van afgelopen evenementen op de website of app.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="pt-100 lg:pt-150">
    <div class="container">
      <div class="w-full xl:w-4/5 mx-auto">
        <div class="flex flex-wrap justify-between items-end">
          <div class="w-full md:w-2/3 md:pr-25">
            <h2 class="text-3xl md:text-4xl xl:text-5xl font-bold">
              <span class="marker">Voorbeelden</span>
              van een sportclub website en <br> app met Clubee
            </h2>
            <p class="text-lg text-gray-500 mt-20 md:max-w-xl">
              Bekijk voorbeelden sportclubs die jou voorgingen, zoals de website van Club of Allstarts en de app van RTC Twente. Ontdek wat Clubee kan betekenen voor jouw club en vraag een demo aan om te zien hoe een app en website voor jouw sportclub eruit zou zien.
            </p>
          </div>
          <div class="flex flex-col md:flex-row gap-20 mt-50">
            <div class="flex-1 bg-gray-100 rounded-3xl p-10 overflow-hidden flex flex-col justify-between">
              <img
                class="ml-auto -mr-10"
                src="/img/case-cas.webp"
                alt="sportclub website"
                title="sportclub website"
              >
              <a
                href="https://www.clubofallstars.nl/"
                target="_blank"
                rel="noopener noreferrer"
                class="relative block bg-white rounded-2xl p-25 lg:p-40 group hover:no-underline hover:bg-gray-200 transition"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-20 h-20 absolute top-0 right-0 m-25 lg:m-40"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                  />
                </svg>
                <h5 class="text-xl font-bold mb-10 group-hover:underline">
                  Club of Allstars
                </h5>
                <p class="text-gray-500">
                  Club of Allstars zocht naar een makkelijke manier om golfwedstrijden met haar leden te delen. Met de app voor sportverenigingen delen ze nu gemakkelijk nieuws, updaten ze leden live en zien ze direct wie zich opgeven.
                </p>
              </a>
            </div>
            <div class="flex-1 bg-gray-100 rounded-3xl p-10 overflow-hidden flex flex-col justify-between">
              <a
                href="https://www.rtctwente.nl/"
                target="_blank"
                rel="noopener noreferrer"
                class="relative block bg-white rounded-2xl p-25 lg:p-40 group hover:no-underline hover:bg-gray-200 transition"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-20 h-20 absolute top-0 right-0 m-25 lg:m-40"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                  />
                </svg>
                <h5 class="text-xl font-bold mb-10 group-hover:underline">
                  RTC Twente
                </h5>
                <p class="text-gray-500">
                  Met Clubee heeft Rijwiel Tourclub Twente de perfecte oplossing gevonden voor hun ledenadministratie. Voorheen deden zij dit met Excel maar met Clubee gaat het een stuk eenvoudiger. Dit bespaart tijd en gedoe.
                </p>
              </a>
              <img
                src="/img/case-rtc.webp"
                alt="sportclub app"
                title="sportclub app"
                class="-ml-10 -mb-10"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <contact-form-section
    id="contact"
    name="Sportclubs"
    text="Met de gratis demo ervaar je zelf hoe ledenadministratie, een website én app voor je sportclub werkt. Ervaar het gemak van Clubee en vraag direct een gratis demo aan."
  >
    <template #title>
      <h2 class="text-3xl xl:text-4xl font-bold mb-10">
        Vraag nu een <span class="marker marker-white">gratis demo</span> aan <br>voor jouw sportclub
      </h2>
    </template>
  </contact-form-section>

  <section class="pt-100 lg:pt-150">
    <div class="container">
      <div class="w-full xl:w-4/5 mx-auto grid md:grid-cols-12 gap-20 items-center md:gap-50">
        <div class="col-span-6 lg:col-span-5">
          <h2 class="text-3xl md:text-4xl xl:text-5xl font-bold mb-25">
            Bereken de kosten voor jouw sportclub
          </h2>
          <p class="xl:text-xl text-gray-500 mb-25">
            Direct weten hoeveel een sportclub app, website én ledenadministratie kost? Bereken het zelf met onze handige tool, of neem direct contact op voor meer info.
          </p>
          <router-link
            :to="{
              path: '/',
              hash: '#tarieven',
            }"
            class="inline-flex font-semibold px-20 lg:px-25 py-10 lg:py-15 rounded-full text-white bg-gradient-to-r from-gray-600 via-gray-900 to-gray-600 bg-size-200 bg-pos-0 hover:bg-pos-100 hover:no-underline transition-all"
          >
            Bereken direct
          </router-link>
        </div>
        <div class="col-span-6 lg:col-span-7">
          <img
            src="/img/tarieven.png"
            alt="kosten sportclub software"
            title="kosten sportclub software"
          >
        </div>
      </div>
    </div>
  </section>

  <section class="pt-100 lg:pt-150 pb-50 lg:pb-100">
    <div class="container">
      <div class="w-full xl:w-4/5 mx-auto">
        <div class="flex flex-wrap items-start">
          <div class="leading-loose">
            <h5 class="text-3xl xl:text-4xl font-bold mb-10">
              Contact
            </h5>
            <p class="text-gray-500 leading-normal">
              Wil je meer weten over Clubee of heb je een andere vraag? Wij helpen je graag! Stuur ons een mail of bel direct met ons voor meer informatie.
            </p>

            <div class="grid md:grid-cols-12 gap-20 md:gap-50 mt-40">
              <figure class="md:col-span-2">
                <img
                  src="/img/contact.jpg"
                  alt="Clubee"
                  class="rounded-2xl w-full"
                >
              </figure>
              <div class="md:col-span-4">
                <h6 class="text-lg xl:text-xl/tight font-semibold mb-20">
                  Neem contact op
                </h6>
                <ul class="leading-normal text-black">
                  <li class="flex items-center space-x-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="text-primary-500"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <span>Bel naar</span>
                    <a
                      href="tel:+31854019360"
                      class="text-black underline hover:no-underline"
                    >
                      085 - 401 9360
                    </a>
                  </li>
                  <li class="flex items-center space-x-5 mt-10">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="text-primary-500"
                    >
                      <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                      <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
                    </svg>
                    <span>Mail naar</span>
                    <a
                      href="mailto:info@clubee.nl"
                      class="text-black underline hover:no-underline"
                    >
                      info@clubee.nl
                    </a>
                  </li>
                </ul>
              </div>
              <div class="md:col-span-5">
                <h6 class="text-lg xl:text-xl/tight font-semibold mb-20">
                  Support
                </h6>
                <p class="leading-normal text-gray-500">
                  Voor ondersteuning van je Clubee website kun je contact opnemen via
                  <a
                    href="mailto:support@clubee.nl"
                    class="text-black underline hover:no-underline"
                  >support@clubee.nl</a> of
                  <a
                    href="tel:+851304667"
                    class="text-black underline hover:no-underline"
                  >085 - 130 4667</a>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import ContactFormSection from '@/components/ContactFormSection.vue';

export default defineComponent({
  components: {
    ContactFormSection,
  },
});
</script>
